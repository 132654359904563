import React, {
    useEffect,
    useCallback,
    useRef,
    ReactNode,
    useState,
} from 'react';
import styles from './Report.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Text } from '../../../components/text/Text';
import {
    GoogleMap,
    Marker,
    useLoadScript,
    Autocomplete,
} from '@react-google-maps/api';
import { Textarea } from '../../../components/textArea/Textarea';
import { useDispatch } from 'react-redux';
import {
    useAppStateReportSelector,
    setDescription,
    setRating,
    setAddress,
    addFiles,
    clearFiles,
    setMarkerPosition,
    setInvalidAddress,
    setInvalidDescription,
    setInvalidImages,
    setInvalidCategory,
    setInvalidRating,
    setCategoryCanSubmit,
    setFileTooLarge,
    setValidGoogleMapsAddress,
} from './reportSlice';
import { Button } from '../../../components/button/Button';
import { LatLng } from './reportAppState';
import {
    useDropzone,
    DropzoneRootProps,
    DropzoneInputProps,
} from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import { submitReport } from './reportActionCreators';
import { config } from '../../../config';
import { Spinner } from '../../../components/spinner/Spinner';
import { ReportStepContainer } from './ReportStepContainer';
import { useTranslation } from 'react-i18next';
import { reportConfigs } from './reportConfigs';
import numeral from 'numeral';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { elginBounds } from '../../../constants';

const libraries = ['places']; // Should be defined outside useLoadScript https://github.com/JustFly1984/react-google-maps-api/issues/238

const ReportHeader = ({ reportName }: { reportName: string }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.reportHeader}>
            <div className={styles.type}>
                {t('Report.ReportAProblem')}: {reportName}
            </div>
            <div className={styles.instructions}>
                {t('Report.ServiceIsNonEmergency')}
            </div>
        </div>
    );
};

const FeedbackHeader = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.reportHeader}>
            <div className={styles.type}>
                {t('Report.REPORT_FEEDBACK_TITLE')}
            </div>
            <div className={styles.instructions}>
                {t('Report.ServiceIsNonEmergency')}
            </div>
        </div>
    );
};

const ReportRating = ({ stepNumber }: { stepNumber: string }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { invalidRating } = useAppStateReportSelector((state) => state);

    function handleOnChangeRating(value: number): void {
        dispatch(setRating(value));
    }

    return (
        <ReportStepContainer
            number={stepNumber}
            title={t('Report.RateUs')}
            containsError={invalidRating}
        >
            <span className={styles.starRating} style={{ textAlign: 'center' }}>
                <input
                    type="radio"
                    name="rating"
                    value="1"
                    onChange={() => handleOnChangeRating(1)}
                />
                <i></i>
                <input
                    type="radio"
                    name="rating"
                    value="2"
                    onChange={() => handleOnChangeRating(2)}
                />
                <i></i>
                <input
                    type="radio"
                    name="rating"
                    value="3"
                    onChange={() => handleOnChangeRating(3)}
                />
                <i></i>
                <input
                    type="radio"
                    name="rating"
                    value="4"
                    onChange={() => handleOnChangeRating(4)}
                />
                <i></i>
                <input
                    type="radio"
                    name="rating"
                    value="5"
                    onChange={() => handleOnChangeRating(5)}
                />
                <i></i>
            </span>
        </ReportStepContainer>
    );
};

interface AutocompleteMapProps {
    center: LatLng;
    markerPosition: LatLng;
    address: string;
    onAddressChange: (val: string) => void;
    onMarkerPositionChange: (val: LatLng) => void;
}

const AutocompleteMap = ({
    center,
    markerPosition,
    address,
    onAddressChange,
    onMarkerPositionChange,
}: AutocompleteMapProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [userLocation, setUserLocation] = useState<any>();
    const autocompleteRef = useRef(
        null as unknown as google.maps.places.Autocomplete,
    );
    const markerRef = useRef(null as unknown as google.maps.Marker);
    const geocoder = new google.maps.Geocoder();

    useEffect(() => {
        if (!userLocation) {
            getLocation();
        }
    });

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                showUserPosition,
                showError,
            );
        } else {
            console.log('geolocation is not supported by this browser');
        }
    };

    const showUserPosition = (position: any) => {
        console.log(position);
        const coordinates = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        setUserLocation(coordinates);
        onMarkerPositionChange(coordinates);
        const currentLatLng = new google.maps.LatLng(
            coordinates.lat,
            coordinates.lng,
        );
        geocoder.geocode({ location: currentLatLng }, (res) => {
            const addressString = res.length
                ? res[0].formatted_address
                : t('Report.NoAddressFound');
            onAddressChange(addressString);
        });
    };

    const showError = (error: any) => {
        console.log(error);
    };

    const containerStyle = {
        width: '100%',
        height: '400px',
        alignSelf: 'center',
    };

    const onMarkerLoad = (markerInstance: google.maps.Marker) => {
        markerRef.current = markerInstance;
    };

    const onMarkerMouseUP = async (e: any) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        onMarkerPositionChange({ lat, lng });
        const currentLatLng = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: currentLatLng }, (res) => {
            const addressString = res.length
                ? res[0].formatted_address
                : t('Report.NoAddressFound');
            onAddressChange(addressString);
        });
    };

    const onAutocompleteLoad = (
        autocompleteInstance: google.maps.places.Autocomplete,
    ) => {
        autocompleteRef.current = autocompleteInstance;
    };

    const onAutocompletePlaceChange = () => {
        const place = autocompleteRef.current.getPlace();
        if (place) {
            onAddressChange(place.formatted_address as string);

            const lat = place.geometry?.location.lat() ?? markerPosition.lat;
            const lng = place.geometry?.location.lng() ?? markerPosition.lng;
            onMarkerPositionChange({ lat, lng });
        }
    };
    return (
        <>
            <Autocomplete
                onLoad={onAutocompleteLoad}
                onPlaceChanged={onAutocompletePlaceChange}
                bounds={
                    new google.maps.LatLngBounds(
                        new google.maps.LatLng(
                            elginBounds.south,
                            elginBounds.west,
                        ),
                        new google.maps.LatLng(
                            elginBounds.north,
                            elginBounds.east,
                        ),
                    )
                }
            >
                <Text
                    value={address}
                    onChange={(e: any, data: any) => {
                        onAddressChange(data.value);
                        dispatch(setValidGoogleMapsAddress(false));
                    }}
                />
            </Autocomplete>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
            >
                <>
                    <Marker
                        onLoad={onMarkerLoad}
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={onMarkerMouseUP}
                    />
                </>
            </GoogleMap>
        </>
    );
};

const ReportAddress = ({ stepNumber }: { stepNumber: string }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = useAppStateReportSelector((state) => state);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: config.googleMapsKey,
        libraries,
    });

    const center = state.markerPosition;
    const position = state.markerPosition;

    return (
        <ReportStepContainer
            number={stepNumber}
            title={t('Report.Address')}
            containsError={state.invalidAddress}
        >
            {t('Report.EnterLocationOfProblem')}
            {isLoaded ? (
                <AutocompleteMap
                    center={center}
                    markerPosition={position}
                    address={state.address}
                    onAddressChange={(val) => dispatch(setAddress(val))}
                    onMarkerPositionChange={(val) => {
                        dispatch(setValidGoogleMapsAddress(true));
                        dispatch(setMarkerPosition(val));
                    }}
                />
            ) : (
                <div className={styles.spinnerContainer}>
                    <Spinner isLoading={true} />
                </div>
            )}
        </ReportStepContainer>
    );
};

const ReportCategory = ({
    reportName,
    innerComponent,
    stepNumber,
}: {
    reportName: string;
    innerComponent: ReactNode;
    stepNumber: string;
}) => {
    const { t } = useTranslation();
    const { invalidCategory } = useAppStateReportSelector((state) => state);
    return (
        <ReportStepContainer
            number={stepNumber}
            title={t('Report.ReportCategoryType')}
            containsError={invalidCategory}
        >
            <Text value={reportName} disabled />
            {innerComponent}
        </ReportStepContainer>
    );
};

const ReportDescription = ({ stepNumber }: { stepNumber: string }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { description, invalidDescription } = useAppStateReportSelector(
        (state) => state,
    );
    return (
        <ReportStepContainer
            number={stepNumber}
            title={t('Report.Description')}
            containsError={invalidDescription}
        >
            {t('Report.DescribeIssueInDetail')}:
            <Textarea
                value={description}
                onChange={(e: any, data: any) =>
                    dispatch(setDescription(data.value))
                }
            />
        </ReportStepContainer>
    );
};

const FeedbackDescription = ({ stepNumber }: { stepNumber: string }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { description, invalidDescription } = useAppStateReportSelector(
        (state) => state,
    );
    return (
        <ReportStepContainer
            number={stepNumber}
            title={t('Report.Description')}
            containsError={invalidDescription}
        >
            {t('Report.DescribeFeedbackInDetail')}:
            <Textarea
                value={description}
                onChange={(e: any, data: any) =>
                    dispatch(setDescription(data.value))
                }
            />
        </ReportStepContainer>
    );
};

interface ReportImagesProps {
    getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
    getInputProps: (
        props?: DropzoneInputProps | undefined,
    ) => DropzoneInputProps;
    isDragActive: boolean;
    stepNumber: string;
}

const ReportImages = ({
    getRootProps,
    getInputProps,
    isDragActive,
    stepNumber,
}: ReportImagesProps) => {
    const { t } = useTranslation();
    const { files, invalidImages, fileTooLarge } = useAppStateReportSelector(
        (state) => state,
    );
    return (
        <ReportStepContainer
            number={stepNumber}
            title={t('Report.UploadImages')}
            containsError={invalidImages}
        >
            <div {...getRootProps()} className={styles.fileUpload}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>{t('Util.DropFilesHere')}</p>
                ) : (
                    <p>{t('Util.DragDropHere')}</p>
                )}
            </div>
            {!fileTooLarge ? (
                <>
                    <div className={styles.fileSizeLimit}>
                        <p>{t('Util.FileSizeLimit', { count: 10 })}</p>
                    </div>
                    <div className={styles.fileList}>
                        {files.map((file) => (
                            <div className={styles.fileItem} key={file.id}>
                                <span>{file.name}</span>
                                <span>{file.type}</span>
                                <span>
                                    {numeral(file.size).format('0.0 b')}
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.fileSizeError}>
                        {t('Util.FilesTooLarge', { count: 10 })}
                    </div>
                </>
            )}
        </ReportStepContainer>
    );
};

export const Report = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const reportState = useAppStateReportSelector((state) => state);
    const {
        address,
        invalidAddress,
        description,
        rating,
        invalidDescription,
        invalidImages,
        categoryCanSubmit,
        invalidCategory,
        invalidRating,
        fileTooLarge,
        submitting,
        validGoogleMapsAddress,
    } = reportState;

    const clearErrors = () => {
        dispatch(setInvalidAddress(false));
        dispatch(setInvalidImages(false));
        dispatch(setInvalidDescription(false));
        dispatch(setInvalidCategory(false));
        dispatch(setInvalidRating(false));
    };

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(setCategoryCanSubmit(false));
        };
        // eslint-disable-next-line
    }, [dispatch]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            dispatch(clearFiles(null));
            dispatch(setFileTooLarge(false));
            let totalFileSize = 0;
            acceptedFiles.forEach((file: any) => {
                totalFileSize += file.size;
                dispatch(
                    addFiles({
                        id: uuid(),
                        name: file.name,
                        size: file.size,
                        type: file.type,
                    }),
                );
            });
            if (totalFileSize > 10485760) {
                dispatch(setFileTooLarge(true));
            }
        },
        [dispatch],
    );

    const onDropRejected = useCallback(
        (rejectedFiles) => {
            rejectedFiles.forEach((file: any) => {
                if (file.errors && file.errors[0].code === 'file-too-large') {
                    dispatch(setFileTooLarge(true));
                }
            });
        },
        [dispatch],
    );

    // Dropzone kept out to pass acceptedFiles to actionCreator without putting non-serializable objects in redux store
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            onDrop,
            onDropRejected,
            multiple: true,
            maxSize: 10485760,
        });

    const searchParams = new URLSearchParams(location.search);

    const pathName = window.location.pathname;
    let reportName = searchParams.get('item') || '';
    let activityId = searchParams.get('id') || '';
    let isFeedbackForm = false;
    if (pathName === '/feedback' || reportName === 'APP_FEEDBACK') {
        reportName = 'APP_FEEDBACK';
        activityId = '65993E24-E054-41D0-BEBB-1172B12BFD00';
        isFeedbackForm = true;
    } else {
        if (!activityId || !reportName || !reportConfigs[activityId]) {
            return <p>{t('Report.ReportTypeNotFound')}</p>;
        }
    }

    const reportConfig = reportConfigs[activityId];
    const activityLanguageKey = `Activity.REPORT_${reportName}`;
    const translatedReportName = t(activityLanguageKey);

    if (reportConfig.showInsteadOfForm) {
        return (
            <PageContainer>
                <ReportHeader reportName={translatedReportName} />
                {reportConfig.showInsteadOfForm}
            </PageContainer>
        );
    }

    let stepNumber = 1;
    let canSubmit = true;
    const onSubmit = () => {
        canSubmit = true;
        clearErrors();
        if (
            (!address || !validGoogleMapsAddress) &&
            reportConfig.addressNotRequired !== true
        ) {
            dispatch(setInvalidAddress(true));
            canSubmit = false;
        }
        if (reportConfig.stepTwoComponent && !categoryCanSubmit) {
            dispatch(setInvalidCategory(true));
            canSubmit = false;
        }
        if (!reportConfig.hideAddImages && fileTooLarge) {
            dispatch(setInvalidImages(true));
            canSubmit = false;
        }
        if (!description) {
            dispatch(setInvalidDescription(true));
            canSubmit = false;
        }
        if (isFeedbackForm && (!rating || rating === 0)) {
            dispatch(setInvalidRating(true));
            canSubmit = false;
        }
        if (!canSubmit) {
            return;
        }

        const reportSpecificSalesforceData =
            reportConfig.prepareReportSpecificSalesforceData(reportState);
        dispatch(
            submitReport(
                acceptedFiles,
                reportSpecificSalesforceData,
                activityId,
                activityLanguageKey,
                history,
            ),
        );
    };

    return (
        <PageContainer>
            {isFeedbackForm ? (
                <FeedbackHeader />
            ) : (
                <ReportHeader reportName={translatedReportName} />
            )}
            <div className={styles.columnContainer}>
                {!isFeedbackForm ? (
                    <div className={styles.column}>
                        <ReportAddress stepNumber={String(stepNumber++)} />
                    </div>
                ) : null}
                <div className={styles.column}>
                    {reportConfig.stepTwoComponent ? (
                        <ReportCategory
                            reportName={reportName}
                            innerComponent={reportConfig.stepTwoComponent}
                            stepNumber={String(stepNumber++)}
                        />
                    ) : null}
                    {isFeedbackForm ? (
                        <FeedbackDescription
                            stepNumber={String(stepNumber++)}
                        />
                    ) : (
                        <ReportDescription stepNumber={String(stepNumber++)} />
                    )}
                    {!reportConfig.hideAddImages ? (
                        <ReportImages
                            stepNumber={String(stepNumber++)}
                            getRootProps={getRootProps}
                            getInputProps={getInputProps}
                            isDragActive={isDragActive}
                        />
                    ) : null}
                </div>
                <div className={styles.column}>
                    {isFeedbackForm ? (
                        <ReportRating stepNumber={String(stepNumber++)} />
                    ) : null}
                </div>
                <div className={styles.reportFooter}>
                    <Button
                        onClick={onSubmit}
                        fluid={false}
                        loading={submitting}
                        disabled={submitting}
                    >
                        {t('Util.Submit')}
                    </Button>
                    {(invalidAddress ||
                        invalidCategory ||
                        invalidDescription ||
                        invalidImages ||
                        invalidRating) && (
                        <div style={{ textAlign: 'left' }}>
                            <p
                                className={`${styles.errorMessage} ${styles.errorMessageHeader}`}
                            >
                                {t('Activity.SubmitWarning')}
                            </p>
                            <ul className={styles.errorMessageLi}>
                                {invalidAddress && (
                                    <li className={styles.errorMessage}>
                                        {t('Activity.SubmitWarningAddress')}
                                    </li>
                                )}
                                {invalidCategory && (
                                    <li className={styles.errorMessage}>
                                        {t('Activity.SubmitWarningCategory')}
                                    </li>
                                )}
                                {invalidDescription && (
                                    <li className={styles.errorMessage}>
                                        {t('Activity.SubmitWarningDescription')}
                                    </li>
                                )}
                                {invalidImages && (
                                    <li className={styles.errorMessage}>
                                        {t('Activity.SubmitWarningImages')}
                                    </li>
                                )}
                                {invalidRating && (
                                    <li className={styles.errorMessage}>
                                        Invalid rating, please refresh and try
                                        again. If this issue persists please
                                        contact support.
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </PageContainer>
    );
};
