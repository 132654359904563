import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './DelinquencyNotifications.module.scss';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import moment from 'moment';
import { Button, Modal } from 'semantic-ui-react';
import { Icon } from '../../components/icon/Icon';
import { DelinquencyModal } from './DelinquencyModal';
import { axios } from '../../services/axiosService';

export const DelinquencyNotifications = () => {
    const [delinquencyNotifications, setDelinquencyNotifications] = useState(
        [],
    );
    const [verifiedData, setVerifiedData] = useState<any[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [notificationsLoading, setNotificationsLoading] = useState(false);
    const [sendNotificationLoading, setSendNotificationLoading] =
        useState(false);
    const [disableModalSendNotification, setDisableModalSendNotification] =
        useState(false);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const getDelinquencyNotifications = useCallback(async () => {
        const response = await axios.get('/notifications/delinquencies');
        setDelinquencyNotifications(response.data);
    }, []);

    useEffect(() => {
        getDelinquencyNotifications();
    }, [getDelinquencyNotifications]);

    const verifyDelinquencyData = useCallback(async (document: string) => {
        setSendNotificationLoading(true);
        const response = await axios.post(
            '/notifications/delinquencies/verify',
            {
                delinquencyData: document,
            },
        );

        setVerifiedData(response.data);
        setDisableModalSendNotification(false);
        setModalOpen(true);
        setSendNotificationLoading(false);
    }, []);

    const submitNotifications = useCallback(async () => {
        // /notifications/delinquencies

        setNotificationsLoading(true);
        await axios.post('/notifications/delinquencies', {
            delinquencyData: verifiedData,
        });

        setNotificationsLoading(false);
        setModalOpen(false);
        getDelinquencyNotifications();
    }, [verifiedData, getDelinquencyNotifications]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];

            // read the file
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target?.result;
                if (content) {
                    const fileContent = content.toString();
                    verifyDelinquencyData(fileContent);
                }
            };

            reader.readAsText(file);

            fileInputRef.current!.value = '';
        }
    };

    return (
        <PageContainer>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size="large"
            >
                <Modal.Header>
                    Delinquency Notification Summary
                    <div style={{ float: 'right' }}>
                        <Icon
                            icon="x"
                            color="black"
                            size="large"
                            onClick={() => setModalOpen(false)}
                        />
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <DelinquencyModal
                        onClose={() => {
                            setModalOpen(false);
                        }}
                        selectedFileContent={verifiedData}
                    />
                    {!disableModalSendNotification && (
                        <Button
                            onClick={() => {
                                submitNotifications();
                            }}
                            loading={notificationsLoading}
                            style={{ margin: 'auto' }}
                        >
                            Send Notifications
                        </Button>
                    )}
                </Modal.Content>
            </Modal>

            <div className={styles.buttonHeader}>
                <Button
                    onClick={handleButtonClick}
                    loading={sendNotificationLoading}
                >
                    Send Notification
                </Button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </div>
            <div>
                <div className={styles.action_container}>
                    <h1 className={styles.title}>Delinquency Notifications</h1>

                    <table>
                        <tr className={styles.tableHeader}>
                            <th className={styles.alertEnglish}>
                                Batch Identifier
                            </th>
                            <th className={styles.alertSpanish}>
                                Users Reached
                            </th>
                            <th className={styles.alertEnglish}>Sent</th>
                            <th className={styles.actions}>Actions</th>
                        </tr>
                        {delinquencyNotifications?.map(
                            (notification: any, index) => (
                                <tr
                                    className={`listItemColor ${styles.item}`}
                                    key={notification.Id}
                                >
                                    <td className={styles.alertText}>
                                        <span className={styles.alertHeader}>
                                            {notification.Id}
                                        </span>
                                    </td>
                                    <td className={styles.alertText}>
                                        <span className={styles.alertHeader}>
                                            {notification.processed}
                                        </span>
                                    </td>
                                    <td className={styles.timestamp}>
                                        {moment(notification.createdAt)
                                            .tz('America/Chicago')
                                            .format('MM-DD-YY h:mm A')}
                                    </td>
                                    <td className={styles.actions}>
                                        <Button
                                            loading={false}
                                            disabled={false}
                                            onClick={() => {
                                                setVerifiedData(
                                                    JSON.parse(
                                                        notification.data,
                                                    ),
                                                );
                                                setDisableModalSendNotification(
                                                    true,
                                                );
                                                setModalOpen(true);
                                            }}
                                        >
                                            View Details
                                        </Button>
                                    </td>
                                </tr>
                            ),
                        )}
                    </table>
                </div>
            </div>
        </PageContainer>
    );
};
