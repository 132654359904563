import React from 'react';
import styles from './SideBarMenu.module.scss';
import { NavLink } from 'react-router-dom';
import { useAuthSelector } from '../../features/auth/authSlice';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '../../features/featureFlags/FeatureFlag';

const SidebarItem = ({
    to,
    iconClassName,
    text,
}: {
    to: string;
    iconClassName: string;
    text: string;
}) => (
    <NavLink to={to} activeClassName={styles.active}>
        <div className={styles.item}>
            <div className={`${styles.sideBarMenuLinkTextContainer}`}>
                <i className={iconClassName} />
                {text}
            </div>
        </div>
    </NavLink>
);

export function setSidebarTopHeight(height?: number) {
    // get the height of the system alert box
    const alert = document.querySelector(
        '.Panel_panelRow__6kRPE',
    ) as HTMLElement;

    if (alert) {
        // set the height of the 'sideBarMenu' class object
        const alertHeight = alert.offsetHeight + 130;
        const sideBarMenu = document.querySelector(
            '.SideBarMenu_sideBarMenu__wKrLW',
        ) as HTMLElement;
        if (sideBarMenu) {
            if (height !== undefined) {
                sideBarMenu.style.top = `${height}px`;
            } else {
                sideBarMenu.style.top = `${alertHeight}px`;
            }
        }
    }
}

export const SideBarMenu: React.FunctionComponent = () => {
    const { isAdmin, authUser } = useAuthSelector((state) => state);
    const { t } = useTranslation();
    const waterBillPaymentRecordsFF = useFeatureFlag(
        'EnableWaterBillHistoryPage',
    );
    const boardsAndCommissionsFF = useFeatureFlag('EnableBoardsAndCommissions');

    return (
        <div className={styles.sideBarMenu}>
            <h2>{t('SideBar.Menu')}</h2>
            <div className={styles.menu}>
                <SidebarItem
                    to="/dashboard"
                    iconClassName="far fa-home"
                    text={t('SideBar.Home')}
                />
                <SidebarItem
                    to="/in_progress"
                    iconClassName="far fa-spinner"
                    text={t('SideBar.InProgress')}
                />
                <SidebarItem
                    to="/completed_activities"
                    iconClassName="far fa-check-circle"
                    text={t('SideBar.CompletedActivity', { count: 2 })}
                />
                {waterBillPaymentRecordsFF?.enabled && (
                    <SidebarItem
                        to="/payment/water-bill-payment-records"
                        iconClassName="far fa-credit-card"
                        text={t('SideBar.Payment')}
                    />
                )}
                <SidebarItem
                    to={`/account/${authUser?.Id}`}
                    iconClassName="far fa-user-circle"
                    text={t('SideBar.MyProfile')}
                />
                {boardsAndCommissionsFF?.enabled && (
                    <SidebarItem
                        to={`/boards-and-commissions`}
                        iconClassName="far fa-users"
                        text={t('SideBar.BoardsAndCommissions')}
                    />
                )}
            </div>

            {isAdmin ? (
                <div className={`${styles.admin}`}>
                    <h2>{t('SideBar.Administration')}</h2>
                    <div className={styles.menu}>
                        <SidebarItem
                            to="/activities"
                            iconClassName="far fa-file-alt"
                            text={t('SideBar.Activity', { count: 2 })}
                        />
                        <SidebarItem
                            to="/languages"
                            iconClassName="far fa-language"
                            text={t('SideBar.Language', { count: 2 })}
                        />
                        <SidebarItem
                            to="/users"
                            iconClassName="far fa-users-cog"
                            text={t('SideBar.User', { count: 2 })}
                        />
                        <SidebarItem
                            to="/saleforce_public_fields"
                            iconClassName="far fa-clipboard-check"
                            text={t('SideBar.SalesforcePublicField', {
                                count: 2,
                            })}
                        />
                        <SidebarItem
                            to="/system_alerts"
                            iconClassName="far fa-bell"
                            text={t('SideBar.SystemAlerts', { count: 2 })}
                        />
                        <SidebarItem
                            to="/bulk_push_notifications"
                            iconClassName="far fa-envelope"
                            text={'Bulk Push Notifications'}
                        />
                        <SidebarItem
                            to="/delinquency_notifications"
                            iconClassName="far fa-alarm-clock"
                            text={'Delinquencies'}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};
